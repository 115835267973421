import ReactMarkdown from "react-markdown"
import styled from "styled-components"

import { ParentSize } from "@visx/responsive"

import { UpgradeIconButton } from "src/components/FeatureBlockers/UpgradeIconButton"
import {
  TGraphData,
  useGetGraphData,
} from "src/components/Homes/DeviceDetails/Overview/useGetGraphData"
import { CreateNoiseIncidentReportButton } from "src/components/Reports/CreateNoiseIncidentReportButton"
import { IncidentReportDialogContainer } from "src/components/Reports/IncidentReportDialogContainer"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { IHome } from "src/data/homes/types/homeTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { getTemperatureUnitWithFallback } from "src/data/user/logic/userTemperature"
import { useFlags } from "src/hooks/useFlags"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Divider } from "src/ui/Divider/Divider"
import { MotionBarGraph } from "src/ui/Graphs/BarGraph/MotionBarGraph"
import { ITimeScopedThreshold } from "src/ui/Graphs/configurationUtils"
import { LineChart } from "src/ui/Graphs/LineGraph/LineChart"
import { LineGraphResponsive } from "src/ui/Graphs/LineGraph/LineGraph"
import { MInfo } from "src/ui/Info/MInfo"
import { MText } from "src/ui/MText"
import { Show } from "src/ui/Show/Show"
import { spacing } from "src/ui/spacing"
import { formatTemperatureUnitString } from "src/utils/l10n"

import { GraphCard } from "./GraphCard"

export type IGraphDateRange = {
  startDate: Date
  endDate: Date
}

export function DeviceGraphs({
  device,
  home,
  dateRange,
  instantlyTurnOnAlarm,
  hideGraphBorder = false,
}: {
  device: TDevice
  home: IHome
  dateRange: IGraphDateRange
  instantlyTurnOnAlarm: boolean | null
  hideGraphBorder?: boolean
}) {
  const { t } = useTranslate()

  const { showLegacyGraphs } = useFlags()

  const { sound, motion, humidity, temperature, timeDomain } = useGetGraphData({
    dateRange,
    device,
    home,
  })

  function MotionInfo() {
    /** If instantlyTurnOnAlarm is null, the user might not have
     permissions to fetch the profile, or it hasn't completed fetching yet.
     at which point we don't show anything */
    if (instantlyTurnOnAlarm === null) {
      return null
    }

    return (
      <MInfo
        content={
          <ReactMarkdown>
            {instantlyTurnOnAlarm
              ? t(langKeys.device_details_motion_detail)
              : t(
                  langKeys.device_details_motion_detail_with_instantly_turn_on_alarm_off
                )}
          </ReactMarkdown>
        }
      />
    )
  }

  return (
    <GraphsBox>
      {!motion.hidden && (
        <GraphCard
          title={
            <MotionGraphTitle>
              {t(langKeys.motion)} <MotionInfo />
            </MotionGraphTitle>
          }
          isLoading={motion.isLoading}
          hideGraphBorder={hideGraphBorder}
          isLegacyGraph
        >
          <ParentSize>
            {(parent) => (
              <MotionBarGraph
                width={parent.width}
                height={parent.height}
                data={motion.data || []}
                bucketSize={motion.bucketSize}
              />
            )}
          </ParentSize>
        </GraphCard>
      )}

      <NoiseGraphCard
        data={sound.data}
        hideGraphBorder={hideGraphBorder}
        timezone={home.timezone}
        thresholds={sound.thresholds}
        homeId={home.home_id}
        startDate={dateRange.startDate}
      />

      <GraphCard
        title={`${t(langKeys.sound_noise_monitoring)} (dB)`}
        isLoading={sound.isLoading}
        hideGraphBorder={hideGraphBorder}
        hidden={!showLegacyGraphs}
        isLegacyGraph
      >
        <LineGraphResponsive
          data={sound.data}
          unitSymbol="dB"
          stepThresholds={sound.thresholds}
          timeDomain={timeDomain}
        />
      </GraphCard>

      <TemperatureGraphCard
        data={temperature.data}
        timezone={home.timezone}
        hideGraphBorder={hideGraphBorder}
        yPlotLines={temperature.thresholds}
      />

      <GraphCard
        title={`${t(langKeys.temperature)} (${formatTemperatureUnitString(
          temperature.unit
        )})`}
        isLoading={temperature.isLoading}
        hideGraphBorder={hideGraphBorder}
        hidden={!showLegacyGraphs}
        isLegacyGraph
      >
        <LineGraphResponsive
          data={temperature.data}
          unitSymbol={formatTemperatureUnitString(temperature.unit)}
          yMaxOffset={2}
          yMinOffset={2}
          timeDomain={timeDomain}
          tooltipRoundingFn={(val: number) => Math.round(val * 10) / 10}
          lineThresholds={temperature.thresholds}
          showValuesBelowZero
        />
      </GraphCard>

      <HumidityGraphCard
        data={humidity.data}
        timezone={home.timezone}
        hideGraphBorder={hideGraphBorder}
        yPlotLines={humidity.thresholds}
      />

      <GraphCard
        title={`${t(langKeys.humidity)} (%)`}
        isLoading={humidity.isLoading}
        hideGraphBorder={hideGraphBorder}
        hidden={!showLegacyGraphs}
        isLegacyGraph
      >
        <LineGraphResponsive
          data={humidity.data}
          unitSymbol="%"
          yMaxOffset={10}
          yMinOffset={10}
          lineThresholds={humidity.thresholds}
          timeDomain={timeDomain}
        />
      </GraphCard>
    </GraphsBox>
  )
}

const GraphsBox = styled.div`
  display: grid;
  gap: ${spacing.M};
`

type LineChartCardProps = {
  data: TGraphData
  loading?: boolean
  thresholds?: ITimeScopedThreshold[]
  yPlotLines?: number[]
  timezone: IHome["timezone"]
  hideGraphBorder: boolean
  hidden?: boolean
}

function NoiseGraphCard({
  data,
  loading,
  hideGraphBorder,
  timezone,
  hidden,
  thresholds,
  homeId,
  startDate,
}: LineChartCardProps & { homeId: string; startDate: Date }) {
  const { t, langKeys } = useTranslate()
  const user = useGetUser()
  const noiseIncidentFeature = useFeatureAvailability({
    feature: "reports_incident",
  })
  const showUpgradeButton = !noiseIncidentFeature.available

  const unitSymbol = `dB`
  const title = `${t(langKeys.sound_noise_monitoring)} (${unitSymbol})`

  return (
    <GraphCard
      title={title}
      isLoading={loading}
      hideGraphBorder={hideGraphBorder}
      hidden={hidden}
    >
      <NoiseCardInnerBox>
        {data && (
          <LineChart
            data={data.flat()}
            tooltip={{ unit: ` ${unitSymbol}`, decimals: 0 }}
            timezone={timezone}
            clockType={user.clock_type}
            thresholds={thresholds}
          />
        )}

        <Show if={!!data}>
          <Divider />

          <FlexBox>
            <div>
              <MText variant="subtitle" as="span">
                {t(langKeys.noise_graph_incident_report_body)}
              </MText>{" "}
              <UpgradeIconButton
                context={"incident_reports"}
                hidden={!showUpgradeButton}
              />
            </div>

            <CreateNoiseIncidentReportButton
              variant="secondary"
              size="small"
              prefillData={{ homeId, date: startDate }}
            />
            <IncidentReportDialogContainer context="device_page" />
          </FlexBox>
        </Show>
      </NoiseCardInnerBox>
    </GraphCard>
  )
}

const NoiseCardInnerBox = styled.div`
  display: grid;
  gap: ${spacing.XL};
`

const FlexBox = styled.div`
  display: flex;
  gap: ${spacing.XS};
  align-items: center;
  justify-content: space-between;
`

function TemperatureGraphCard({
  data,
  hidden,
  loading,
  hideGraphBorder,
  yPlotLines,
  timezone,
}: LineChartCardProps) {
  const { t, langKeys } = useTranslate()
  const user = useGetUser()
  const temperatureUnit = getTemperatureUnitWithFallback(user)
  const unitSymbol = formatTemperatureUnitString(temperatureUnit)
  const title = `${t(langKeys.temperature)} (${unitSymbol})`

  return (
    <GraphCard
      title={title}
      isLoading={loading}
      hideGraphBorder={hideGraphBorder}
      hidden={hidden}
    >
      {data && (
        <LineChart
          data={data.flat()}
          tooltip={{ unit: ` ${unitSymbol}`, decimals: 1 }}
          timezone={timezone}
          clockType={user.clock_type}
          // MON-454: Add support for temperature graph thresholds
          yPlotLines={yPlotLines}
        />
      )}
    </GraphCard>
  )
}

function HumidityGraphCard({
  data,
  loading,
  hideGraphBorder,
  timezone,
  yPlotLines,
  hidden,
}: LineChartCardProps) {
  const { t, langKeys } = useTranslate()
  const user = useGetUser()
  const unitSymbol = `%`
  const title = `${t(langKeys.humidity)} (${unitSymbol})`

  return (
    <GraphCard
      title={title}
      isLoading={loading}
      hideGraphBorder={hideGraphBorder}
      hidden={hidden}
    >
      {data && (
        <LineChart
          data={data.flat()}
          tooltip={{ unit: ` ${unitSymbol}`, decimals: 0 }}
          timezone={timezone}
          clockType={user.clock_type}
          yPlotLines={yPlotLines}
        />
      )}
    </GraphCard>
  )
}

const MotionGraphTitle = styled.div`
  display: flex;
  gap: ${spacing.XS};
`
